import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import "../blog/Blog.css";


// Import the background image
import backgroundImage from "../../assets/images/slide2.jpeg";

const Top = () => {
    const sectionStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#a20000',
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                data-id="d29e141"
                data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
                style={{ ...sectionStyle }}
            >
                <div className="elementor-background-overlay"></div>
                <Container className="elementor-container elementor-column-gap-no">
                    <Row className="elementor-row">
                        <Col className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f" data-id="0a1c64f" data-element_type="column">
                            <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                    <div
                                        className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title"
                                        data-id="b12b60d"
                                        data-element_type="widget"
                                        data-widget_type="modina_section_title.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="block-contents text-center">
                                                <div className="section-title">
                                                    <h5 className="text-center"></h5>
                                                    <span></span>
                                                    <h2>About Us</h2>
                                                </div>
                                       </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className="top-summary" style={{ padding: "30px", color: "grey" }}>
      <Row>
        <Col>
          <p>
            Gautam Solar (www.gautamsolar.com) has been ranked among Top 10 Solar Module Manufacturer (Source: “RE Update Q2 2023”, August 2023; “India Solar Annual Report Card CY2022”, March 2023). It has 27+ years of solar industry experience with 4 factories in Haridwar, India, and a corporate office in New Delhi, India. Using the latest machines and technology, Gautam Solar’s solar panels are manufactured using first-hand top-line machines. It has registered multiple Patents & IPs in its name and is known for technically superior and innovative solar modules.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Gautam Solar manufactures a range of high-efficiency Mono PERC and TOPCon Solar Modules, and its modules are ALMM enlisted and approved by the Bureau of Indian Standards (BIS) for the Indian market and certified as per UL/IEC/EN standards for the US and European markets.
          </p>
        </Col>
      </Row>
    </Container>

           
        </>
    );
};

export default Top;

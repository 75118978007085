import React, { useEffect } from "react";
import { FaCheckCircle } from 'react-icons/fa';
import "aos/dist/aos.css"; // Import AOS styles
import AOS from 'aos'; // Import AOS library
import "../about/About.css";
import { Link } from 'react-router-dom'; // Import Link from React Router


function About() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <>
      <section className="py-5 text-center container">
        <div className="row py-5" data-aos="fade-up"> {/* Apply AOS fade-up animation */}
          <div className="col-lg-8 col-md-10 mx-auto text-center">
            <h1 className="fw-bold" style={{ position: 'relative' }}>
              <span style={{
                position: 'absolute',
                top: '-10px',
                left: '50%',
                transform: 'translateX(-50%)',
                borderBottom: '2px solid #faab04',
                width: '50px',
              }}></span>
              About <span style={{ fontWeight: 'bold', color: '#a20000' }}>Us</span>
            </h1>
            <p className="custom-paragraph lead text-body-secondary">
            Leading Solar Module Manufacturer using cutting-edge, fully-automated setup and latest technology to manufacture Technically Advanced Solar Panels                      
            </p>
          </div>
        </div>
        <div className="row py-lg-0">
          <div className="col-lg-6 col-md-12 mx-auto text-center mb-4 mb-lg-0" data-aos="fade-right">
            <ul className="list-unstyled text-left">
              <li className="mb-2 d-flex align-items-start">
                <FaCheckCircle style={{ color: '#a20000', marginRight: '8px', fontSize: '1.2em', flexShrink: 0 }} />
                <span>In Solar since 1997 – Can withstand Long 25/30 Years warranty</span>
              </li>
              <li className="d-flex align-items-start">
                <FaCheckCircle style={{ color: '#a20000', marginRight: '8px', fontSize: '1.2em', flexShrink: 0 }} />
                <span>Long Term Outlook – 100% Equity with promoters</span>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-12 mx-auto text-center mb-4 mb-lg-0" data-aos="fade-right"> 
            <ul className="list-unstyled text-left">
              <li className="mb-2 d-flex align-items-start">
                <FaCheckCircle style={{ color: '#a20000', marginRight: '8px', fontSize: '1.2em', flexShrink: 0 }} />
                <span>Profitable in Solar since the last 27 years</span>
              </li>
              <li className="d-flex align-items-start">
                <FaCheckCircle style={{ color: '#a20000', marginRight: '8px', fontSize: '1.2em', flexShrink: 0 }} />
                <span>Bankable Company for Solar Modules</span>
              </li>
            </ul>
          </div>

          <div className="py-3 col-12 text-center" data-aos="fade-right">
      <Link to="/aboutus" style={{ backgroundColor: "#a20000" }} className="btn btn-danger">
        Read More
      </Link>
    </div>
        </div>

      </section>
    </>
  );
}

export default About;

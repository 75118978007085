import React from 'react';
import { Card, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import certificate from "../../assets/images/Patents & IPs.jpg";
import standard from "../../assets/images/UL-STANDARDS.jpg";
import wideangle from "../../assets/images/State of the art.jpg";
import thoughtl from "../../assets/images/thought-leaders-banner-rechange-630x461-1.webp";

const SolarPanel = () => {
  return (
    <section className="py-5 text-center container">
      <div >
        <div className="col-lg-9 col-md-8 mx-auto text-center">
          <h2 className="fw-bold" style={{ position: 'relative', color: "black" }}>
            <span style={{
              position: 'absolute',
              top: '-15px',
              left: '50%',
              transform: 'translateX(-50%)',
              borderBottom: '2px solid #faab04',
              width: '50px',
            }}></span>
            Why <span style={{ fontWeight: 'bold', color: '#a20000' }}> Choose Gautam Solar  </span>
          </h2>
        </div>
      </div>
      <Container className="my-5 px-3 text-center">
        <Row className="elementor-row">
          <Col md={6} className="elementor-column elementor-col-12 mb-4">
            <Card className="elementor-widget elementor-widget-image text-center h-100">
              <Card.Img
                variant="top"
                src={wideangle}
                className="img-fluid"
                alt="State-of-the-art factories"
              />
              <Card.Body>
                <Card.Title style={{ color: "black" }}>4 State-of-the-art factories</Card.Title>
                <Card.Text style={{ textAlign: "center", lineHeight: "1.5" }}>
                 Manufacturing Fully Automated Setup<br></br>
                 
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="elementor-column elementor-col-12 mb-4">
            <Card className="elementor-widget elementor-widget-image text-center h-100">
              <Card.Img
                variant="top"
                src={certificate}
                className="img-fluid"
                alt="Patents & IPs"
              />
              <Card.Body>
                <Card.Title style={{ color: "black" }}>Patents & IPs</Card.Title>
                <Card.Text style={{ textAlign: "center", lineHeight: "1.5" }}>
                  Strong focus on R&D and <br></br>
                  indigenous innovation in the Solar Industry
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="my-5 px-3 text-center">
        <Row className="elementor-row">
          <Col md={6} className="elementor-column elementor-col-12 mb-4">
            <Card className="elementor-widget elementor-widget-image text-center h-100">
              <Card.Img
                variant="top"
                src={standard}
                className="img-fluid"
                alt="Quality Certifications"
              />
              <Card.Body>
                <Card.Title style={{ color: "black" }}>Quality Certifications</Card.Title>
                <Card.Text style={{ textAlign: "center", lineHeight: "1.5" }}>
                  Certified as per global standards to <br></br>
                  provide assurance of quality and safety
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="elementor-column elementor-col-12 mb-4">
            <Card className="elementor-widget elementor-widget-image text-center h-100">
              <Card.Img
                variant="top"
                src={thoughtl}
                className="img-fluid"
                alt="Thought Leaders of Solar Industry"
              />
              <Card.Body>
                <Card.Title style={{ color: "black" }}>Thought Leaders of Solar Industry</Card.Title>
                <Card.Text style={{ textAlign: "center", lineHeight: "1.5" }}>
                  Solar Industry Experts shaping the <br></br>
                  future of sustainability
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SolarPanel;
